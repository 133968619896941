@font-face {
    font-family: 'PhoenixSansBoldWeb';
    src: url('./theme/fonts/PhoenixSans-Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'PhoenixSansMediumWeb';
    src: url('./theme/fonts/PhoenixSans-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PhoenixSansRegularWeb';
    src: url('./theme/fonts/PhoenixSans-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'PhoenixSerifRegularWeb';
    src: url('./theme/fonts/PhoenixSerif-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome 6 Free';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url('./theme/fonts/fontawesome/fa-solid-900.woff2') format('woff2'),
        url('./theme/fonts/fontawesome/fa-solid-900.ttf') format('truetype');
}

.fas,
.fa-solid {
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
}
