/* App.css */
#root {
}

.auth-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.isDisabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  a[aria-disabled="true"] {
    color: currentColor;
    display: inline-block;  /* For IE11/ MS Edge bug */
    pointer-events: none;
    text-decoration: none;
  }